import axios from 'axios'
import store from '../store'

import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '../routes'

import {type_json} from './utils'
import Bridges from './bridges'
import Localisation from './localisation'
import Users from './users'
import Auth from './auth'
import Files from './files'
import Reports from './reports'
import WebGis from './webgis'
import Dashboard from './dashboard'
import Permissions from './permissions'
import UnitCosts from './unit-costs'
import Subnetworks from './subnetworks'
import Plans from './plans'
import Disasters from './disasters'

const router = new VueRouter({
  mode: 'history', routes // short for index: index
})

Vue.use(VueRouter)

const {VUE_APP_BACK_SERVER} = process.env
const API_ROOT = VUE_APP_BACK_SERVER

const Api = axios.create({
  baseURL: API_ROOT, withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Access-Control-Expose-Headers': 'Location'
  }
})

Api.interceptors.response.use((res) => {
  return res
}, async (err) => {
  if (err.response.status === 401) {
    console.log("interceptors 401")
    store.dispatch('LOGOUT').then(()=>{})
    router.push('/log-in')
  } else {
    return err
  }

})

export default {

  isSetHeaderAuth() {
    return (Api.defaults.headers.hasOwnProperty('Authorization') && Api.defaults.headers.common['Authorization'] !== '')
  }, setHeaderAuth(token) {
    Api.defaults.headers['Authorization'] = `Bearer ${token}`
  }, delHeaderAuth() {
    delete Api.defaults.headers.common['Authorization']
  },

  /*
                                                                    ---------  APP OPTIONS  -----------------
                                                                    */
  /**
   * App
   * @returns {Object} - 200
   * {
   *  <String>sidebar_text,
   *  <String>title_text,
   *  <String>logo
   * }
   * @throws Error
   */
  load_app_options() {
    return Api.get(`/app`, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * App unit types
   * @returns {Promise<AxiosResponse<any>>} - 200
   * @throws Error
   */
  load_app_unit_types() {
    return Api.get(`/app/unit-types`, {
      headers: {
        ...type_json
      }
    })
  },


  // Api modules
  bridges: Bridges(Api),
  localisation: Localisation(Api),
  users: Users(Api),
  auth: Auth(Api),
  files: Files(Api),
  reports: Reports(Api),
  webGis: WebGis(Api),
  dashboard: Dashboard(Api),
  permissions: Permissions(Api),
  unitCosts: UnitCosts(Api),
  subNetworks: Subnetworks(Api),
  plans: Plans(Api),
  disasters: Disasters(Api)
}
